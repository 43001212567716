import request from '@/utils/request'

export function fetchFlowLog(params) {
  return request({
    url: '/FundFlowLog/' + (params.method || 'Search'),
    method: 'post',
    data: params
  });
}

export function importFlowLog(cardAlias, fileID) {
  return request({
    url: 'FundFlowLog/Import/' + cardAlias + '?FileID=' + fileID,
    method: 'get',
    loadTarget: 'load-dialog'
  });
}

export function fetchBankList() {
  return request({
    url: '/FundFlowLog/BankList',
    method: 'get'
  });
}


export function bankAnalyse(keyword) {
  return request({
    url: '/FundReport/BankAnalyse?keyword=' + (keyword || ''),
    method: 'get'
  });
}

export function fetchBillLog(params) {
  return request({
    url: '/FundBillLog/' + (params.method || 'Search'),
    method: 'post',
    data: params
  });
}
export function getBillCode() {
  return request({
    url: '/FundBillLog/GetBillCode',
    method: 'get'
  });
}
export function submitBatch(data) {
  return request({
    url: '/FundBillLog/Batch',
    method: 'post',
    data: data
  });
}